@import '../../styles/variables';

#brand-logo {
  background-color: $primary-color !important;
  border-color: $body-color !important;
  color: $body-color;
  font-weight: bold;
  padding: 5px;
  margin-left: 1rem;
}

#navbar-toggle {
  margin-right: 1rem;
}
