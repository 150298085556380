@import "variables";

.navbar {
  background-color: $primary-color !important;
  a {
    color: $body-color !important;
    text-decoration: none;
  }
}

button.btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.navbar-nav a.active {
  font-weight: bold;
}

.navbar-dark .navbar-toggler {
  color: rbga(216, 216, 216, 0.5);
  border-color: rbga(216, 216, 216, 0.1);
}

.btn-outline-primary {
  color: $primary-color !important;
  border-color: $primary-color !important;
}

.card-title,
.card-body {
  padding: 0.75rem;
}

.card-title {
  border-bottom: 1px solid $primary-color;
}

.container-fluid {
  max-width: 95%;
}

.btn-primary:focus {
  box-shadow: none;
}
