body {
  background-color: $body-color !important;
  height: 100%;
  color: $text-color;
  font-family: "Roboto", sans-serif;
}

a {
  color: $primary-color;
  text-decoration: underline;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

main {
  flex: 1 1 auto;
}

.section-header {
  font-weight: 700;
  color: $primary-color;
}

header,
main,
footer {
  flex-shrink: 0;
}

footer {
  background-color: $primary-color;
  color: white;
  white-space: pre;
  a {
    color: white;
  }
  a:hover {
    color: white;
  }
}
