.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-center {
  display: flex;
  align-items: center;
  height: 100%;
}

.text-align-center {
  text-align: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.space-right {
  margin-right: 10px;
}

.full-height {
  height: 100%;
}

.space-top-lg {
  padding-top: 25px;
}

.space-top-md {
  padding-top: 10px;
}

.space-top-sm {
  padding-top: 5px;
}

.margin-top-sm {
  margin-top: 5px;
}

.title {
  font-weight: 500;
}

.no-bottom-margin {
  margin-bottom: 0px;
}

.space-around {
  margin-left: 5px;
  margin-right: 5px;
}
